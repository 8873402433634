import { Prop, Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "@/mixins/DataTableMixin";
import { invoke, map } from "lodash";

@Component({})
export default class FiltersRowMixin extends Vue {
  @Prop() readonly trHeaders!: DataTableHeader[];
  obFilterData: Record<string, any> = {};

  get headerValueList(): string[] {
    return map(this.trHeaders, "value");
  }

  onUpdateFilters() {
    invoke(this, "refreshPagination");
    invoke(this, "onSetFilters", this.obFilterData);
  }

  onResetFilters() {
    this.obFilterData = {};
    this.onUpdateFilters();
  }
}
